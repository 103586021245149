"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const DOMElementsConstants_1 = require("../../../../constants/DOMElementsConstants");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const FiltersMobileButton_1 = require("./FiltersMobileButton");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const AppliedFiltersMobile_1 = require("../../applied-filters/AppliedFiltersMobile");
const FiltersMobileList_1 = require("./FiltersMobileList");
const common_types_1 = require("@as-react/common-types");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const InStoreFilterMobile_1 = require("../in-store-filter/InStoreFilterMobile");
const useThrottle_1 = require("../../../../hooks/useThrottle");
var VerticalDirection;
(function (VerticalDirection) {
    VerticalDirection["UP"] = "up";
    VerticalDirection["DOWN"] = "down";
})(VerticalDirection || (VerticalDirection = {}));
const BUTTON_TEXT_WHEN_FILTERS_CLOSED = 'product:product.lister.filter.button';
const FiltersMobile = ({ filters, filterWizardConfig, fredhopperGlossary, isContentTileLister, itemCount, onFilterItemChange, removeAllFilters, storeOptions, urlFilters, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([cotopaxi_1.I18N_NAMESPACES.product]);
    const [navigationEl, setNavigationEl] = (0, react_1.useState)();
    const [isStickyFiltersOpen, setIsStickyFiltersOpen] = (0, react_1.useState)(false);
    const [isButtonHidden, setIsButtonHidden] = (0, react_1.useState)(true);
    const buttonRef = (0, react_1.useRef)(null);
    const [isFilterChanged, setIsFilterChanged] = (0, react_1.useState)(false);
    const getStickyButtonText = () => {
        if (!isStickyFiltersOpen) {
            return isContentTileLister ? 'general:content.overview.filter.title' : BUTTON_TEXT_WHEN_FILTERS_CLOSED;
        }
        return isContentTileLister
            ? 'general:content.overview.filter.articles'
            : 'product.lister.filter.button.show_result';
    };
    const displayHandler = (0, useThrottle_1.useThrottle)((direction, button, navigation, listerContainer) => {
        if (!button || !navigation) {
            return;
        }
        const { top, bottom } = button.getBoundingClientRect();
        const buttonOffset = direction === VerticalDirection.UP ? top : bottom;
        const isNavDisplayed = !navigation.classList.contains(DOMElementsConstants_1.NAVIGATION_HORIZONTAL_COLLAPSE);
        const listerContainerBottom = (listerContainer === null || listerContainer === void 0 ? void 0 : listerContainer.getBoundingClientRect().bottom) || 0;
        setIsButtonHidden(Boolean(window.innerHeight - listerContainerBottom > 0 ||
            Math.floor(isNavDisplayed ? buttonOffset - DOMElementsConstants_1.NAVIGATION_HEIGHT : top) >= 0));
    }, GeneralConstants_1.DELAY.DELAY_500);
    (0, react_1.useEffect)(() => {
        setNavigationEl(document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL));
        displayHandler(VerticalDirection.DOWN, buttonRef.current, navigationEl);
    }, []);
    (0, cotopaxi_1.useScrollPosition)(({ prevPos, currPos }) => {
        const direction = prevPos.y < currPos.y ? VerticalDirection.UP : VerticalDirection.DOWN;
        const listerContainer = document.getElementById(common_types_1.LISTER_ID);
        displayHandler(direction, buttonRef.current, navigationEl, listerContainer);
    }, [buttonRef, navigationEl]);
    (0, react_1.useEffect)(() => {
        setIsFilterChanged(true);
    }, [urlFilters]);
    const handleIsStickyFiltersOpen = () => {
        const listerContainer = document.getElementById(common_types_1.LISTER_ID);
        listerContainer &&
            isFilterChanged &&
            isStickyFiltersOpen &&
            !isButtonHidden &&
            ScrollUtil_1.ScrollUtil.scrollToPosition(listerContainer.offsetTop - ScrollUtil_1.NAVIGATION_MENU_OFFSET);
        setIsFilterChanged(false);
        setIsStickyFiltersOpen(!isStickyFiltersOpen);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { ref: buttonRef, children: (0, jsx_runtime_1.jsx)(FiltersMobileButton_1.FiltersMobileButton, { text: isContentTileLister
                        ? t('general:content.overview.filter.articles', {
                            count: itemCount,
                        })
                        : t(BUTTON_TEXT_WHEN_FILTERS_CLOSED), onClick: handleIsStickyFiltersOpen, isContentTileLister: isContentTileLister }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.StickyBar, { isHidden: !isStickyFiltersOpen && isButtonHidden, collapsed: !isStickyFiltersOpen, variant: cotopaxi_1.StickyBarVariant.FILTERS, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.BASE, text: t(getStickyButtonText(), {
                        count: itemCount,
                    }), iconRight: isStickyFiltersOpen
                        ? undefined
                        : {
                            name: cotopaxi_1.IconName.FILTER_NEW,
                            size: cotopaxi_1.IconSize.MEDIUM,
                        }, fill: isStickyFiltersOpen, onClick: handleIsStickyFiltersOpen }) }), (0, jsx_runtime_1.jsx)(andes_react_1.BottomSheet, { isOpen: isStickyFiltersOpen, padding: "slim", onClose: handleIsStickyFiltersOpen, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 8 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.L, children: t(isContentTileLister ? 'general:content.overview.filter.title' : 'product:product.lister.filter.title') }) }), (0, jsx_runtime_1.jsxs)(andes_react_1.Stack, { gap: "4", children: [(0, jsx_runtime_1.jsx)(AppliedFiltersMobile_1.AppliedFiltersMobile, {}), !isContentTileLister && (storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.showInStoreFilter) && ((0, jsx_runtime_1.jsx)(InStoreFilterMobile_1.default, { inStoreFilter: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.inStoreFilter, activeStoreId: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.activeStoreId, handleOpenPopup: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.openInStoreFilterPopup, updateInStoreFilter: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.updateInStoreFilter, updateStoreId: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.updateStoreId })), (0, jsx_runtime_1.jsx)(FiltersMobileList_1.FiltersMobileList, { filterWizardConfig: filterWizardConfig, onFilterItemChange: onFilterItemChange, filters: filters, itemCount: itemCount, isContentTileLister: isContentTileLister, removeAllActiveFilters: removeAllFilters, fredhopperGlossary: fredhopperGlossary, urlFilters: urlFilters })] })] }) })] }));
};
exports.FiltersMobile = FiltersMobile;
