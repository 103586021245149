"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_STORE_STORAGE_KEY = exports.SELECTED_STORE_STORAGE_KEY = exports.RATING = exports.SALE_ID = exports.CUSTOMER_UUID = exports.SHOP_NUMBER_STORAGE_KEY = exports.DEVICE_ID_STORAGE_KEY = exports.PERSONAL_SHOPPING_ADVICE_STORAGE_KEY = exports.BASKET_CHANGED_STORAGE_KEY = exports.BUY_GET_POPUP_STORAGE_KEY = exports.APPLE_PAY_PAYMENT_FAILED = exports.HAS_ACTIVE_SESSION = exports.IS_NEW_SESSION = exports.CHOSEN_PAYMENT_OPTION = exports.REGISTRATION_EMAIL = exports.PRODUCT_COMPARE_LIST = exports.GUEST_EMAIL = exports.BASKET_CHANGED = exports.COOKIE_CONSENT_ACCEPTED = exports.PENULT_CRUMB_URL_KEY = exports.PENULTIMATE_VISITED_URL_KEY = exports.LAST_VISITED_URL_KEY = void 0;
exports.LAST_VISITED_URL_KEY = 'lastVisitedUrl';
exports.PENULTIMATE_VISITED_URL_KEY = 'penultimateVisitedUrl';
exports.PENULT_CRUMB_URL_KEY = 'penultCrumbUrl';
exports.COOKIE_CONSENT_ACCEPTED = 'cookieConsentAccepted';
exports.BASKET_CHANGED = 'basketChanged';
exports.GUEST_EMAIL = 'guestEmail';
exports.PRODUCT_COMPARE_LIST = 'product_compare_list';
exports.REGISTRATION_EMAIL = 'registrationEmail';
exports.CHOSEN_PAYMENT_OPTION = 'chosenPaymentOption';
exports.IS_NEW_SESSION = 'isNewSession';
exports.HAS_ACTIVE_SESSION = 'hasActiveSession';
exports.APPLE_PAY_PAYMENT_FAILED = 'applePayPaymentFailed';
exports.BUY_GET_POPUP_STORAGE_KEY = 'buyAndGetPopupOpened';
exports.BASKET_CHANGED_STORAGE_KEY = 'basketChanged';
exports.PERSONAL_SHOPPING_ADVICE_STORAGE_KEY = 'personalShoppingAdvice';
exports.DEVICE_ID_STORAGE_KEY = 'device_id';
exports.SHOP_NUMBER_STORAGE_KEY = 'shopNumber';
exports.CUSTOMER_UUID = 'customerUUID';
exports.SALE_ID = 'saleId';
exports.RATING = 'rating';
exports.SELECTED_STORE_STORAGE_KEY = 'selectedStore';
exports.SEARCH_STORE_STORAGE_KEY = 'searchStore';
