"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GridTileContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const ProductTileVertical_1 = require("../product-tile/vertical/ProductTileVertical");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const RowBanner_1 = require("./row-banner/RowBanner");
const BannerTile_1 = require("./banner-tile/BannerTile");
const GridTilePlaceholder_1 = require("./grid-tile-placeholder/GridTilePlaceholder");
const GridTileContainer = ({ isLoading, items, onTileClick, updateCompareList, onWishListToggle, contentBlocks, singleActiveSizeFilterKeyName, compareItems, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isShopLanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const { lister_colour_swatches_visible_mobile, lister_colour_swatches_visible_desktop } = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const determineLazyLoadState = (index) => index < GeneralConstants_1.PLACEHOLDER_TILES.length
        ? common_types_1.LazyLoadOptions.NO_LAZY_LOAD
        : index < GeneralConstants_1.PLACEHOLDER_TILES.length * 1.5
            ? common_types_1.LazyLoadOptions.LAZY_PRE_LOAD
            : common_types_1.LazyLoadOptions.LAZY_LOAD;
    const generateRowBanner = (contentBlock, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ProductGrid.RowBanner, { visibleOnRow: contentBlock.visibleInRow, children: (0, jsx_runtime_1.jsx)(RowBanner_1.RowBanner, { content: contentBlock.content }) }, `row-banner-item-${index}`));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ProductGrid, { analyticsAreaName: common_types_1.LISTER_ID, rowBanners: contentBlocks.filter(({ content }) => Boolean(content)).map(generateRowBanner), children: isLoading
            ? GeneralConstants_1.PLACEHOLDER_TILES.map((_, index) => (0, jsx_runtime_1.jsx)(GridTilePlaceholder_1.GridTilePlaceholder, {}, `grid-item-${index}`))
            : items.map((item, index) => {
                const shouldLazyLoadImage = determineLazyLoadState(index);
                if ((0, common_types_1.isBanner)(item)) {
                    const { image, url, alt } = item.images[0];
                    return ((0, jsx_runtime_1.jsx)(BannerTile_1.BannerTile, { lazy: shouldLazyLoadImage === common_types_1.LazyLoadOptions.LAZY_LOAD, url: url, image: image, alt: alt }, `grid-item-${index}`));
                }
                return ((0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { product: item, onTileClick: onTileClick, onWishListToggle: onWishListToggle, updateCompareList: updateCompareList, compareItems: compareItems, shouldLazyLoadImage: shouldLazyLoadImage, alwaysShowSwatches: isShopLanding, showSwatchesOnDesktop: lister_colour_swatches_visible_desktop, showSwatchesOnMobile: lister_colour_swatches_visible_mobile, singleActiveSizeFilterKeyName: singleActiveSizeFilterKeyName, showSizesOnHover: !isShopLanding }, `${item.productCode}${item.colourId}${index}`));
            }) }));
};
exports.GridTileContainer = GridTileContainer;
