"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const FilterContent_1 = require("./FilterContent");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const FiltersMobileOld_1 = require("./filters-mobile/FiltersMobileOld");
const FiltersMobile_1 = require("./filters-mobile/FiltersMobile");
const Filter = ({ filters, activeFilters, itemCount, removeAllFilters, onFilterItemChange, fredhopperGlossary, filterWizardConfig, urlFilters, isContentTileLister, storeOptions, }) => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: featureToggles.new_mobile_filter_design ? ((0, jsx_runtime_1.jsx)(FiltersMobile_1.FiltersMobile, { isContentTileLister: isContentTileLister, itemCount: itemCount, filters: filters, filterWizardConfig: filterWizardConfig, fredhopperGlossary: fredhopperGlossary, onFilterItemChange: onFilterItemChange, removeAllFilters: removeAllFilters, storeOptions: storeOptions, urlFilters: urlFilters })) : ((0, jsx_runtime_1.jsx)(FiltersMobileOld_1.FiltersMobileOld, { activeFilters: activeFilters, filters: filters, filterWizardConfig: filterWizardConfig, fredhopperGlossary: fredhopperGlossary, isContentTileLister: isContentTileLister, itemCount: itemCount, onFilterItemChange: onFilterItemChange, removeAllFilters: removeAllFilters, storeOptions: storeOptions, urlFilters: urlFilters })) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: (0, jsx_runtime_1.jsx)(FilterContent_1.FilterContent, { desktop: true, filters: filters, activeFilters: activeFilters, onFilterItemChange: onFilterItemChange, fredhopperGlossary: fredhopperGlossary, filterWizardConfig: filterWizardConfig, itemCount: itemCount, urlFilters: urlFilters }) })] }));
};
exports.Filter = Filter;
