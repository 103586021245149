"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailValidationWrapper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const i18nConstants_1 = require("../../constants/i18nConstants");
const formik_1 = require("formik");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const EmailChangeHandler_1 = require("./EmailChangeHandler");
const EmailValidationUtil_1 = require("./EmailValidationUtil");
const FormValidationUtil_1 = require("../../util/FormValidationUtil");
const cotopaxi_1 = require("@as/cotopaxi");
const Yup = require("yup");
const EmailError_1 = require("./EmailError");
const common_types_1 = require("@as-react/common-types");
const EmailValidationWrapper = ({ emailFieldKey, checkExists, errorMessages, sendEmail, isCollapsedForm = false, children, setIsCollapsedForm, skipValidationFor, }) => {
    var _a;
    const { values, errors, touched, setFieldValue, isSubmitting, status, setStatus } = (0, formik_1.useFormikContext)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [suggestedEmail, setSuggestedEmail] = (0, react_1.useState)();
    const [emailStatus, setEmailStatus] = (0, react_1.useState)(undefined);
    const [skipEmailValidation, setSkipEmailValidation] = (0, react_1.useState)(false);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const emailValue = (0, formik_1.getIn)(values, emailFieldKey);
    const emailError = (0, formik_1.getIn)(errors, emailFieldKey);
    const emailTouched = (0, formik_1.getIn)(touched, emailFieldKey);
    const emailValuePrev = (0, cotopaxi_1.usePrevious)(emailValue);
    const isEqualValue = emailValue === emailValuePrev;
    const requiredMessage = (_a = errorMessages.required) !== null && _a !== void 0 ? _a : t('buy:buy.login.email.error.required');
    (0, react_1.useEffect)(() => {
        setStatus(Object.assign(Object.assign({}, status), { emailError: emailError }));
    }, [emailError]);
    const validateEmailCallback = (0, react_1.useCallback)((0, EmailValidationUtil_1.validateEmail)(servicesEndpoint, skipEmailValidation, emailStatus, setSuggestedEmail, setEmailStatus, true, skipValidationFor), [skipEmailValidation, emailStatus, skipValidationFor]);
    const schema = Yup.string()
        .when('$regularCheck', ([regularCheck], tmpSchema) => regularCheck
        ? tmpSchema.required(requiredMessage).matches(FormValidationUtil_1.default.emailPatternRegex, errorMessages.invalidEmail)
        : tmpSchema)
        .when('$backendEmailValidationEnabled', ([backendValidation], tmpSchema) => backendValidation ? tmpSchema.test(validateEmailCallback).required(requiredMessage) : tmpSchema)
        .when('$checkExists', ([contextCheckExists], tmpSchema) => contextCheckExists
        ? tmpSchema
            .test((0, EmailValidationUtil_1.validateEmailExists)(servicesEndpoint, defaultRequestParameters, errorMessages, true, true, sendEmail, setIsCollapsedForm))
            .required(requiredMessage)
        : tmpSchema)
        .when('$checkExistsWithValidation', ([checkExistsWithValidation], tmpSchema) => checkExistsWithValidation
        ? tmpSchema
            .test((0, EmailValidationUtil_1.validateEmailExists)(servicesEndpoint, defaultRequestParameters, errorMessages, true, true, sendEmail, setIsCollapsedForm, true, skipEmailValidation, emailStatus, setSuggestedEmail, setEmailStatus))
            .required(requiredMessage)
        : tmpSchema);
    const handleEmailValidation = (shouldCheckExists) => (email) => {
        if (emailTouched && (status === null || status === void 0 ? void 0 : status.currentFocusField) !== emailFieldKey && emailValue === email) {
            return status === null || status === void 0 ? void 0 : status.emailError;
        }
        const backendEmailValidationEnabledContext = !(shouldCheckExists && checkExists);
        const checkExistsWithValidationContext = shouldCheckExists && checkExists;
        return schema
            .validate(email, {
            context: {
                checkExists: false,
                backendEmailValidationEnabled: backendEmailValidationEnabledContext,
                checkExistsWithValidation: checkExistsWithValidationContext,
                regularCheck: !backendEmailValidationEnabledContext && !checkExistsWithValidationContext,
            },
        })
            .then(() => undefined)
            .catch(error => error.message);
    };
    const handleEmailChange = () => {
        skipEmailValidation && setSkipEmailValidation(false);
    };
    const handleAcceptClick = () => {
        setEmailStatus(`${common_types_1.EmailValidationResponseStatus.VALID}_${suggestedEmail}`);
        setFieldValue(emailFieldKey, suggestedEmail, true);
    };
    const handleIgnoreClick = () => setSkipEmailValidation(true);
    const renderError = (msg, textProps) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, Object.assign({}, textProps, { children: (0, jsx_runtime_1.jsx)(EmailError_1.EmailError, { emailStatus: msg, suggestedEmail: suggestedEmail, requiredErrorLabel: t('account:delivery.required'), onAcceptClick: handleAcceptClick, onIgnoreClick: handleIgnoreClick }) })));
    return ((0, jsx_runtime_1.jsxs)("div", { children: [children({
                validate: isCollapsedForm && isEqualValue ? undefined : handleEmailValidation(!isSubmitting),
                renderError,
            }), (0, jsx_runtime_1.jsx)(EmailChangeHandler_1.EmailChangeHandler, { emailFieldKey: emailFieldKey, emailValue: (0, formik_1.getIn)(values, emailFieldKey), skipEmailValidation: skipEmailValidation, onEmailChange: handleEmailChange, setEmailStatus: setEmailStatus })] }));
};
exports.EmailValidationWrapper = EmailValidationWrapper;
