"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyLoginForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const Login_1 = require("../../../components/account/authentication/Login");
const ForgotPassword_1 = require("../../../components/account/authentication/ForgotPassword");
const react_redux_1 = require("react-redux");
const CookieConsentSelector_1 = require("../../../redux/cookieConsent/selectors/CookieConsentSelector");
const AuthActions_creators_1 = require("../../../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../../../redux/authentication/selectors/AuthSelectors");
const common_types_1 = require("@as-react/common-types");
const BuyLoginForm = ({ actionUri, enableLoginForm }) => {
    const [forgotPasswordActive, setForgotPasswordActive] = (0, react_1.useState)(false);
    const [email, setEmail] = (0, react_1.useState)('');
    const dispatch = (0, react_redux_1.useDispatch)();
    const cookieConsentSettingAvailable = (0, react_redux_1.useSelector)((0, CookieConsentSelector_1.makeGetCookieConsentSettingAvailable)());
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const loading = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginLoading)());
    const loginError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginError)());
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const toggleView = () => {
        setForgotPasswordActive(!forgotPasswordActive);
        resetPasswordState.success && dispatch(AuthActions_creators_1.authActions.createResetCredentialsSuccessAction(null));
    };
    const handleLoginSubmit = (values) => {
        dispatch(AuthActions_creators_1.authActions.registeredLogin({
            username: values.email,
            password: values.password,
            keepSignedIn: values.keepSignedIn,
            reloadOnSuccess: false,
            location: common_types_1.AuthFormLocation.CHECKOUT,
        }));
    };
    const handleResetPasswordSubmit = (values) => {
        dispatch(AuthActions_creators_1.authActions.createResetCredentialsAction(values.email));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: forgotPasswordActive ? t('account.login.title.password.forgotten') : t('account.login.title.login') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, desktop: { spacing: { marginTop: 3 } }, children: forgotPasswordActive ? ((0, jsx_runtime_1.jsx)(ForgotPassword_1.ForgotPassword, { onSubmit: handleResetPasswordSubmit, errorFeedback: resetPasswordState.error, successFeedback: resetPasswordState.success, initialEmail: email, backToLoginClicked: toggleView, onEmailChange: setEmail })) : ((0, jsx_runtime_1.jsx)(Login_1.Login, { login: {
                        action: actionUri,
                        onSubmit: handleLoginSubmit,
                        errorFeedback: loginError,
                        forgotPasswordClicked: toggleView,
                        initialEmail: email,
                        loading,
                    }, facebook: {}, socialLogin: payload => dispatch(AuthActions_creators_1.authActions.createSocialLoginAction(payload)), onAuthResetForm: () => dispatch(AuthActions_creators_1.authActions.resetError()), onEmailChange: setEmail, cookieConsentSettingAvailable: cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE })) })] }));
};
exports.BuyLoginForm = BuyLoginForm;
