"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const StoreFinderPopup_1 = require("../components/product-detail/store-finder-popup/StoreFinderPopup");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const CustomerSelector_1 = require("../redux/customer/selectors/CustomerSelector");
const SessionSelector_1 = require("../redux/session/selectors/SessionSelector");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const CustomerActions_creators_1 = require("../redux/customer/actions/CustomerActions.creators");
const ActionEnums_1 = require("../enums/ActionEnums");
const ProductDetailV2Container_1 = require("./product/ProductDetailV2Container");
const StoreFinderContainer = ({ type = common_types_1.StoreFinderType.CLICK_AND_COLLECT, }) => {
    var _a;
    const dispatch = (0, react_redux_1.useDispatch)();
    const popupState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketCCPopupState)());
    const profile = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerProfile)());
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const priceInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPriceInformation)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    const { selectedAdditionalProducts } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    (0, react_1.useEffect)(() => {
        if (!profile && isLoggedIn) {
            dispatch(CustomerActions_creators_1.default.getProfile());
        }
    }, [profile, isLoggedIn]);
    const handleAddToBasket = (quantity) => dispatch(selectedAdditionalProducts.length > 0
        ? BasketActions_creators_1.basketActions.createAddAdditionalProductsAction(selectedAdditionalProducts, quantity, ActionEnums_1.ProductAddToBasketLocations.CLICK_AND_COLLECT)
        : BasketActions_creators_1.basketActions.createAddAction(quantity, ActionEnums_1.ProductAddToBasketLocations.CLICK_AND_COLLECT, common_types_1.PDPVersion.V2));
    return ((0, jsx_runtime_1.jsx)(StoreFinderPopup_1.default, { type: type, isOpen: !popupState.collapsed, selectedSku: selectedSku, disabledAddToBasket: Boolean(!((_a = priceInformation === null || priceInformation === void 0 ? void 0 : priceInformation.prices) === null || _a === void 0 ? void 0 : _a.SELL)), addToBasket: handleAddToBasket, handleClosePopup: () => dispatch(BasketActions_creators_1.basketActions.createCCPopupCloseAction()), storeSourceExcluded: Boolean(fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded), setClickAndCollectStoreId: () => dispatch(BasketActions_creators_1.basketActions.createGetAction()) }));
};
exports.default = StoreFinderContainer;
